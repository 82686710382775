import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: '50px',
        fontStyle: 'bold',
    },
    model: {
        width: '18%',
        textAlign: 'left',
    },
    product: {
        width: '48%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'left',
    },
    qty: {
        width: '14%',
        textAlign: 'left',
        paddingRight: 8,
    },
    image: {
        width: '20%',
        height: 'auto',
        textAlign: 'right',
        paddingRight: 8,
        paddingLeft: 8,
    },
    title: {
        color: 'orange',
        fontSize: 15,
    },
    altsClass: {
        color: 'blue',
    },
    extraDesc: {
        color: 'blue',
        fontSize: 10
    }

});

const BundleTableRow = ({ items }) => {
    const rows = (items && (items.length > 0)) && items.map(item =>
        <View style={styles.row} key={item._id}>
            {
                item.title && <Text style={item.class ? styles.altsClass : styles.title}>{item.title ? item.title.replace(/['"]+/g, '') : ''}</Text>
            }
            {
                item.extraDesc && <Text style={styles.extraDesc}>{item.extraDesc ? item.extraDesc.replace(/['"]+/g, '') : ''}</Text>
            }
            {
                item.modelName && <Text style={styles.model}>{item.modelName ? item.modelName.replace(/['"]+/g, '') : ''}</Text>
            }

            {
                item.qty && <Text style={styles.qty}>{item.qty ? item.qty : 0}</Text>
            }

            {
                item.name && <Text style={styles.product}>{item.name ? item.name.replace(/['"]+/g, '') : ''}</Text>
            }

            {
                item.image && <Image style={styles.image} src={item.image ? decodeURIComponent(item.image.replace(/['"]+/g, '')) : ''} />
            }

        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default BundleTableRow