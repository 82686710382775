import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import BundleTableHeader from './BundleTableHeader'
import BundleTableRow from './BundleTableRow'
import BundleTableBlankSpace from './BundleTableBlankSpace'

const tableRowsCount = 1;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    width: '90%',
    marginLeft: '28px'
  },
});

const micBundleRecsText = {
  _id: '0001',
  title: 'Wireless Microphones',
}

const micBundleAltsText = {
  _id: '0002',
  class: 'alts',
  title: 'Also Compatible'
}

const IEMBundleRecText = {
  _id: '0003',
  title: 'In-Ear Monitors'
}

const BundleItemsTable = ({ bundleInfo }) => (
  <View style={styles.tableContainer}>
    <BundleTableHeader />
    {
      bundleInfo.items && (bundleInfo.items.length > 0) ?
        <View>
          {
            (bundleInfo.micBundleRecs && bundleInfo.micBundleRecs.length > 0) &&
            <View>
              <BundleTableRow items={[micBundleRecsText]} />

              <BundleTableRow items={bundleInfo.micBundleRecs} />
            </View>
          }

          {
            (bundleInfo.micBundleAlts && bundleInfo.micBundleAlts.length > 0) &&
            <View>
              <BundleTableRow items={[micBundleAltsText]} />

              <BundleTableRow items={bundleInfo.micBundleAlts} />
            </View>
          }


          {
            (bundleInfo.IEMBundleRecs && bundleInfo.IEMBundleRecs.length > 0) &&
            <View>
              <BundleTableRow items={[IEMBundleRecText]} />

              <BundleTableRow items={bundleInfo.IEMBundleRecs} />
            </View>
          }
        </View>
        :
        <BundleTableBlankSpace rowsCount={tableRowsCount - (bundleInfo.items ? bundleInfo.items.length : 0)} />
    }

  </View>
);

export default BundleItemsTable;