import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import PdfDocument from '../services/pdfGen';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import axios from 'axios';
import VisitorFormComponent from '../components/VisitorForm';
import { AppContext } from '../context/GlobalContext';

const backendURL = process.env.REACT_APP_BACKEND || 'https://rf-venue-backend.herokuapp.com/devices';
const REACT_APP_BASEURL = process.env.REACT_APP_BASEURL || 'https://rf-venue-backend.herokuapp.com';

const VisitorFormContainer = (props) => {

    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        organization: '',
        postalCode: '',
        email: '',
        phone: '',
        capacity: '',
        currentIEMs: '0',
        currentMics: '0',
        newIEMs: '0',
        newMics: '0',
        venue: '',
        venueDescription: '',
        description: '',
        micBundleRecs: [],
        micBundleAlts: [],
        IEMBundleRecs: [],
        items: [],
        IEMNotFoundText: '',
    });

    const changeHandler = e => {
        const value = e.target.value
        setFormState({
            ...formState,
            [e.target.name]: value
        })
        setInputError('')
    }

    const [isEmailSending, setIsEmailSent] = useState(false);

    const [inputError, setInputError] = useState('');

    const generateMicBundle = async (totalMics, devicesInfo) => {

        let micBundle;
        let micBundleAlt;

        if (devicesInfo && (devicesInfo.length > 0)) {

            if ((totalMics > 0) && (totalMics <= 5)) {
                // return DFINDISTRO4 Device
                micBundle = devicesInfo.filter(device => device.modelName === "DFINDISTRO4");
                micBundle = micBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: 1
                    })
                })
                formState.micBundleRecs = micBundle;

            } else if (totalMics > 5 && totalMics <= 9) {

                // return DFINDISTRO4 (1) + DISTRO4(1) Devices
                micBundle = devicesInfo.filter(device => device.modelName === "DFINDISTRO4" || device.modelName === "DISTRO4");
                micBundle = micBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: 1
                    })
                })
                formState.micBundleRecs = micBundle;
                // *On a separate line*
                // Also compatible with DFIND9 (1) Devices
                micBundleAlt = devicesInfo.filter(device => device.modelName === "DFIND9");
                micBundleAlt = micBundleAlt.map((thread) => {
                    return ({
                        ...thread,
                        qty: 1
                    })
                })
                formState.micBundleAlts = micBundleAlt;

            } else if (totalMics > 9 && totalMics <= 12) {
                // return DFIND9 (1) + DISTRO4 Devices
                micBundle = devicesInfo.filter(device => device.modelName === "DFIND9" || device.modelName === "DISTRO4");
                micBundle = micBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: 1
                    })
                })
                console.log(micBundle);
                formState.micBundleRecs = micBundle;

            } else if (totalMics > 12 && totalMics <= 17) {
                // return DFIND9 (1) + DISTRO4 (2) Devices
                micBundle = devicesInfo.filter(device => device.modelName === "DFIND9" || device.modelName === "DISTRO4");
                micBundle = micBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: (thread.modelName === "DISTRO4") ? 2 : 1
                    })
                })
                console.log(micBundle);
                formState.micBundleRecs = micBundle;
                // *On a separate line*
                // Also compatible with DFIND9 (1) + DISTRO9HDR (1) Devices
                micBundleAlt = devicesInfo.filter(device => device.modelName === "DFIND9" || device.modelName === "DISTRO9HDR")
                micBundleAlt = micBundleAlt.map((thread) => {
                    return ({
                        ...thread,
                        qty: 1
                    })
                })
                console.log("Also compatible with", micBundleAlt)
                formState.micBundleAlts = micBundleAlt;

            } else if (totalMics > 17 && totalMics <= 25) {
                // return DFIND9 (1) + DISTRO9HDR (2) Devices
                micBundle = devicesInfo.filter(device => device.modelName === "DFIND9" || device.modelName === "DISTRO9HDR")
                micBundle = micBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: (thread.modelName === "DISTRO9HDR") ? 2 : 1
                    })
                })
                console.log(micBundle);
                formState.micBundleRecs = micBundle;

            } else if (totalMics > 25) {
                formState.micBundleRecs = [{
                    _id: 'X0002',
                    extraDesc: 'Wireless microphone systems with over 25 channels need detailed coordination and setup.  Contact RF Venue at sales@rfvenue.com or contact your Sweetwater Sales Engineer for assistance.',
                    class: 'micBundleRecs'
                }]
            } else {
                // return L
            }
        }
    };

    const generateIEMBundle = async (totalIEMs, devicesInfo) => {
        let iemBundle;
        if (devicesInfo && (devicesInfo.length > 0)) {

            if ((totalIEMs > 0) && (totalIEMs <= 4)) {
                iemBundle = devicesInfo.filter(device => device.modelName === "COMB4CPB")
                iemBundle = iemBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: 1
                    })
                })
                formState.IEMBundleRecs = iemBundle;

                return iemBundle
            } else if (totalIEMs >= 4 && totalIEMs <= 8) {

                iemBundle = devicesInfo.filter(device => device.modelName === "COMB8CPB")
                iemBundle = iemBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: 1
                    })
                })
                formState.IEMBundleRecs = iemBundle;

                return iemBundle

            } else if (totalIEMs > 8 && totalIEMs <= 16) {
                iemBundle = devicesInfo.filter(device => device.modelName === "COMB8CPB")
                iemBundle = iemBundle.map((thread) => {
                    return ({
                        ...thread,
                        qty: 2
                    })
                })
                formState.IEMBundleRecs = iemBundle;

                return iemBundle
            } else if (totalIEMs > 16) {
                formState.IEMBundleRecs = [{
                    _id: 'X0001',
                    extraDesc: 'IEM systems with over 16 channels need detailed coordination and setup.  Contact RF Venue at sales@rfvenue.com or contact your Sweetwater Sales Engineer for assistance.',
                    class: 'IEMBundleRecs'
                }]
                return []
            }
        }
    }

    const history = useHistory();

    const onSubmit = async (e) => {
        e.preventDefault();

        let totalIEMs = parseInt(formState.currentIEMs) + parseInt(formState.newIEMs);
        let totalMics = parseInt(formState.currentMics) + parseInt(formState.newMics);

        if ((totalIEMs > 0) || (totalMics > 0)) {
            setInputError("")
            await getAllDevices(e, totalMics, totalIEMs)

            await downloadPdf(e);
        } else {
            setInputError('Please select a value greater than 0')
        }


    }

    const { handleState } = useContext(AppContext)


    const downloadPdf = async (e) => {
        let updatedState = {
            formState: formState,
            isEmailSending: true
        }
        setIsEmailSent(true)
        handleState(updatedState)
        const doc = <PdfDocument data={formState} />;
        const asPdf = pdf([]);

        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        /**
         * Sending blob data to backend for sending pdf
         */

        saveAs(blob, 'RF Venue_Wireless_System_Upgrade.pdf');

        var file = new File([blob], "RF Venue_Wireless_System_Upgrade.pdf", { lastModified: (new Date()).getTime() });/*create file*/
        const data = new FormData()
        data.append('file', file)
        data.append('firstName', formState.firstName)
        data.append('lastName', formState.lastName)
        data.append('postalCode', formState.postalCode)
        data.append('email', formState.email)

        data.append('capacity', formState.capacity)
        data.append('venue', formState.venue)

        data.append('venueDescription', formState.venueDescription)
        data.append('description', formState.description)

        data.append('currentIEMs', formState.currentIEMs)
        data.append('currentMics', formState.currentMics)

        data.append('newIEMs', formState.newIEMs)
        data.append('newMics', formState.newMics)

        data.append('organization', formState.organization)

        await axios.post(`${REACT_APP_BASEURL}/email?customerEmail=${formState.email}&customerName=${formState.firstName} ${formState.lastName}`, data);

        updatedState = {
            formState: formState,
            isEmailSending: false
        }

        setIsEmailSent(false)
        handleState(updatedState)

        history.push({
            pathname: '/',
        });

    }

    const getAllDevices = async (e, totalMics, totalIEMs) => {
        const res = await axios.get(backendURL);
        await generateMicBundle(totalMics, res.data ? res.data : []);
        await generateIEMBundle(totalIEMs, res.data ? res.data : []);

        formState.items = [...formState.micBundleRecs, ...formState.micBundleAlts, ...formState.IEMBundleRecs]

        setFormState(formState)
        // history.push({
        //     pathname: '/thank-you',
        //     data: formState
        // });
    }

    return (
        <VisitorFormComponent
            formState={formState}
            onSubmit={onSubmit}
            changeHandler={changeHandler}
            inputError={inputError}
        />
    )
}

export default VisitorFormContainer;
