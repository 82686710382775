import React from 'react'
import {Container} from 'reactstrap';

import TopNav from '../components/TopNav';
import Header from '../components/Header';
import VisitorFormContainer from '../containers/VisitorFormContainer';

const Landing = (props) => {

    return (
        <div>
            <TopNav />
            <Header />
            <Container>
                <VisitorFormContainer />
            </Container>
        </div>
    )
}

export default Landing
