import React from "react";
import {
    Page,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import Bundle from "../components/reports/BundleReport";
import logo from "../images/SWT-Configurator-Header.jpg";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: '100%',
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }
})

export default function PdfDocument(props) {
    const pdfData = props.data;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={logo} />
                {props.data ?
                    <Bundle bundleInfo={pdfData} />
                    : ""}
            </Page>
        </Document>
    );
}
