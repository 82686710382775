import React, { useContext } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { AppContext } from '../context/GlobalContext';
import TopNav from '../components/TopNav'

import PdfDocument from '../services/pdfGen';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import logo from '../images/SweetwaterLogo.png'

import '../index.css';

const ThankYou = () => {
    const { currentState } = useContext(AppContext)

    const data = currentState && currentState.formState ? currentState.formState : '';

    const isEmailSending = currentState && currentState.isEmailSending ? currentState.isEmailSending : false;

    let totalIEMs = parseInt(data && data.currentIEMs ? data.currentIEMs : 0) + parseInt(data && data.newIEMs ? data.newIEMs : 0);
    let totalMics = parseInt(data && data.currentMics ? data.currentMics : 0) + parseInt(data && data.newMics ? data.newMics : 0);

    const onClickHandler = async (e) => {
        e.preventDefault();
        console.log(data);

        await downloadPdf(e);

    }

    const downloadPdf = async (e) => {
        e.preventDefault();

        const doc = <PdfDocument data={data} />;
        const asPdf = pdf([]);

        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, 'document.pdf');
    }

    const isDataAvailable = () => {
        if (data && data.firstName && data.lastName) {
            return true
        } else {
            return false
        }
    }


    return (
        <React.Fragment>
            <TopNav />
            <Container fluid className="thank-you-bg">
                {/* <div className="text-left m-header ml-4 text-light">
                        <h1 className="thank-you-title">Thank You!
                        <div className="d-inline float-right mr-5">
                        <img src={logo} height="50vw"></img>
                    </div>
                        </h1>
                    </div> */}
                <Row className="thank-you-content">
                    <Col lg={9}>
                        <h1 className="thank-you-title">Thank You!</h1>
                    </Col>
                    <Col lg={3}>
                        <div className="text-lg-center text-right">
                            <img src={logo} height="50vw" alt="Sweetwater Logo"></img>
                        </div>
                    </Col>
                </Row>

            </Container>
            <Container fluid className="form-bg">
                <Row sm="4" className="pt-lg-3 px-lg-5">
                    <Col lg={4}>
                        <p><b>{data && data.firstName ? data.firstName : ''} {data && data.lastName ? data.lastName : ''}</b></p>
                        <p><b>{data && data.organization ? data.organization : ''}</b></p>
                    </Col>
                    <Col lg={4}>
                        <p>Email: {data && data.email ? data.email : ''}</p>
                    </Col>
                    <Col lg={4}>
                        <p>Wireless Microphones: {totalMics}</p>
                        <p>In-Ear Monitors: {totalIEMs}</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                {
                    isEmailSending && <span>Email sending...</span>
                }
            </Container>
            <Container>
                <p className="mt-4">A copy of your report has been sent to your email.</p>

                {
                    isDataAvailable() ?
                        <Button className="btn-rnd bg-dark px-4 mt-4" onClick={onClickHandler}>Download Report</Button>
                        : <Button className="btn-rnd bg-dark px-4 mt-4" onClick={onClickHandler} disabled>Download Report</Button>
                }

            </Container>

        </React.Fragment>
    )
}

export default ThankYou
