import React from 'react'
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

const AboutUser = (props) => {
    return (
        <React.Fragment>
            <Row form className="mb-5">
                <Col>
                    <h2 className="mb-3">About You:</h2>
                    <h6>Name<span className="text-danger">*</span></h6>
                    <Row form>
                        <Col lg={6}>
                            <FormGroup>
                                <Input type="text" id="firstName" placeholder="First Name" name="firstName" onChange={props.changeHandler} required />
                                <small>First<span className="text-danger">*</span></small>
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Input type="text" id="lastName" placeholder="Last Name" name="lastName" onChange={props.changeHandler} required />
                                <small>Last<span className="text-danger">*</span></small>
                            </FormGroup>
                        </Col>
                    </Row>
                    <h6>Organization<span className="text-danger">*</span></h6>
                    <FormGroup>
                        <Input type="text" id="organization" name="organization" onChange={props.changeHandler} required />
                    </FormGroup>
                    <h6>Email<span className="text-danger">*</span></h6>
                    <FormGroup>
                        <Input type="email" id="email" name="email" onChange={props.changeHandler} required />
                    </FormGroup>
                </Col>
                {/* VENUE INFO */}
                <Col className="ml-5">
                    <h3>Your Venue:</h3>
                    <Row form>
                        <Col>

                            <Label>Choose One:<span className="text-danger">*</span></Label>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="venue" value="House Of Worship" onChange={props.changeHandler} required />{' '}
                                        House Of Worship
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="venue" value="School" onChange={props.changeHandler} required />{' '}
                                        School
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="venue" value="Theater" onChange={props.changeHandler} required />{' '}
                                        Theater
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="venue" value="Corporate" onChange={props.changeHandler} required />{' '}
                                        Corporate
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="venue" value="Band/Live Music" onChange={props.changeHandler} required />{' '}
                                        Band / Live Music
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="venue" value="Other" onChange={props.changeHandler} required />{' '}
                                        Other (please describe)
                                        </Label>
                                <Input type="textarea" name="venueDescription" onChange={props.changeHandler} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Label>Capacity:<span className="text-danger">*</span></Label>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="capacity" value="< 50" onChange={props.changeHandler} required />{' '}
                                        less than 50
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="capacity" value="50-100" onChange={props.changeHandler} required />{' '}
                                        50-100
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="capacity" value="100-500" onChange={props.changeHandler} required />{' '}
                                        100-500
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="capacity" value="500-1000" onChange={props.changeHandler} required />{' '}
                                        500-1000
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="capacity" value="1000-5000" onChange={props.changeHandler} required />{' '}
                                        1000-5000
                                        </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="capacity" value="5000+" onChange={props.changeHandler} required />{' '}
                                        5000+
                                        </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment >
    )
}

export default AboutUser
