import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    backgroundContainer: {
        backgroundColor: '#d7f2f4'
    },
    headerContainer: {
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
    },
    container: {
        marginTop: '6px',
        marginBottom: '12px',
        flexDirection: 'row',
        alignItems: 'center',
    },

    textWidth: {
        width: '33%',
    },
});

const UserInfo = ({ bundleInfo }) => (
    <View style={styles.backgroundContainer}>
        <View style={styles.headerContainer}>
            <Text style={styles.textWidth}>Name: {bundleInfo.firstName} {bundleInfo.lastName}</Text>
            <Text style={styles.textWidth}>Organization: {bundleInfo.organization}</Text>
            <Text style={styles.textWidth}>Email: {bundleInfo.email}</Text>
        </View>
        <View style={styles.container}>
            <Text style={styles.textWidth}>Wireless Microphones: {parseInt(bundleInfo.currentMics) + parseInt(bundleInfo.newMics)}</Text>
            <Text style={styles.textWidth}>In-Ear Monitors: {parseInt(bundleInfo.currentIEMs) + parseInt(bundleInfo.newIEMs)}</Text>
        </View>
    </View>
);

export default UserInfo;