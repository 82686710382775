import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import logo from '../images/logo-main.png'

const Header = () => {
    return (
        <div className="text-center">
            <div>
                <Container fluid className="header-bg">
                    <Row>
                        <Col lg={9} className="text-lg-left text-center text-light main-header-content">
                            <h1 className="main-header-content-size">Wireless Microphone and In-Ear Monitor <br />
                                <span className="main-header-content-size-tools">Accessory Configuration Tool</span>
                            </h1>
                        </Col>
                        <Col lg={3} className="mobile-logo">
                            <img src={logo} alt="Sweetwater Logo" className="header-logo"></img>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <div className="my-5 text-left">
                    <h2>Configure in Real-Time</h2>
                    <h5>
                        This free tool from Sweetwater and RF Venue will provide you with an overview of your wireless audio system and recommended accessory products by RF Venue.
                    </h5>
                </div>
            </Container>
        </div>
    )
}

export default Header
