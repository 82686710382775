import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        color: 'white'
    },
    model: {
        width: '20%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    product: {
        width: '50%',
        textAlign: 'left',
    },
    qty: {
        width: '10%',

        textAlign: 'right',
        paddingRight: 8,
    },
    image: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

const BundleTableBlankSpace = ({ rowsCount }) => {
    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map((x, i) =>
        <View style={styles.row} key={`BR${i}`}>
            <Text style={styles.model}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.product}>-</Text>
            <Text style={styles.image}>-</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default BundleTableBlankSpace