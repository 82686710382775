import React, { useState } from 'react'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import logo from '../images/RFVenueLogo-white.png'

const Topnav = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="dark" dark expand="md" className="pb-3 pr-5">
        <NavbarBrand href="/">
          <img src={logo} height="60vh" alt="RFVenue Logo"></img>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mx-2" nav caret>
                Products
                </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Remote Antennas
                  </DropdownItem>
                <DropdownItem>
                  Antenna Distribution and IEM Combiners
                  </DropdownItem>
                <DropdownItem>
                  RF Explorer® Pro Audio Spectrum Analyzer
                  </DropdownItem>
                <DropdownItem>
                  Optix RF Over Fiber System
                  </DropdownItem>

                <DropdownItem>
                  Multi-Zone Antenna Combiners
                  </DropdownItem>

                <DropdownItem>
                  Bandpass Filters, Coaxial Cables & Parts
                  </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mx-2" nav caret>
                Resources
                </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Blog
                  </DropdownItem>
                <DropdownItem>
                  Where to Buy
                  </DropdownItem>
                <DropdownItem>
                  Case Studies
                  </DropdownItem>
                <DropdownItem>
                  Become a Dealer
                  </DropdownItem>
                <DropdownItem>
                  Dealer Portal
                  </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mx-2" nav caret>
                Contact
                </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  Contact Us
                  </DropdownItem>
                <DropdownItem>
                  About
                  </DropdownItem>
                <DropdownItem>
                  Warranty
                  </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <NavbarText className="ml-5">@ A Glance</NavbarText>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Topnav
