import React from 'react';
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label
} from 'reactstrap';

const IEMInfo = (props) => {
    return (
        <React.Fragment>
            <Row form className="mb-5">
                <Col>
                    <FormGroup>
                        <Label>In-Ear Monitors Currently Owned</Label>
                        <Input type="select" name="currentIEMs" onChange={props.changeHandler} style={{ width: "auto" }}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>New In-Ear Monitors Needed</Label>
                        <Input type="select" name="newIEMs" onChange={props.changeHandler} style={{ width: "auto" }}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg={4}></Col>
            </Row>
        </React.Fragment>
    )
}

export default IEMInfo
