import React from 'react';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import IEMInfo from './Form/IEMInfo';
import MicInfo from './Form/MicInfo';
import AboutUser from './Form/AboutUser';
import Bundle from "../components/reports/BundleReport";
import DevicesTable from "./DevicesTable";

import './Form/form.css'

const VisitorForm = (props) => {
    return (
        <Container className="form-bg">
            <Form className="mb-5 p-3" onSubmit={props.onSubmit}>
                {/* USER INFO */}
                <AboutUser changeHandler={props.changeHandler} />
                <h2 className="mb-3">Wireless Equipment:</h2>
                {/* MIC INFO */}
                <MicInfo changeHandler={props.changeHandler} />
                {/* IEM INFO */}
                <IEMInfo changeHandler={props.changeHandler} />
                {
                    props.inputError &&
                    <div><span className="text-danger">{props.inputError}</span></div>
                }
                <Button size="lg" className="btn-rnd bg-dark px-4">Click to Configure in Real-Time Now</Button>
            </Form>
            {
                (props.formState && props.formState.items && props.formState.items.length > 0) &&
                <DevicesTable bundleInfo={props.formState} />
            }

        </Container>

    )
}

export default VisitorForm;
