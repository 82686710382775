import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AppProvider from './context/GlobalProvider';

import Landing from './pages/Landing';
import ThankYou from './pages/ThankYou';

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact render={props => <Landing {...props} />} />
          <Route
            path="/landing-page"
            exact
            render={props => <Landing {...props} />}
          />
          <Route path='/thank-you' exact render={props => <ThankYou {...props} />} />
        </Switch>
      </BrowserRouter>
      </AppProvider>
  );
}

export default App;
