import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import footerLogo from "../../../src/images/logo-main.png";

const styles = StyleSheet.create({

    titleContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: '5%'
    },
    textFontSize: {
        fontSize: 9,
    },

    logo: {
        width: '10%',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
});

const BundleFooter = () => (
    <View style={styles.titleContainer}>
        <Image style={styles.logo} src={footerLogo} />
        <Text style={styles.textFontSize}>sweetwater.com |</Text>
        <Text style={styles.textFontSize}> Toll-free 800.222.4700 |</Text>
        <Text style={styles.textFontSize}> Espanol 800.222.4701 |</Text>
        <Text style={styles.textFontSize}> Local 260.432.8176 |</Text>
        <Text style={styles.textFontSize}> Fax 260.432.1758</Text>
    </View>
);

export default BundleFooter