import React from 'react';
import DeviceTableRow from './DevicesTableRow';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

const tableRowsCount = 1;

const micBundleRecsText = {
    _id: '0001',
    title: 'Wireless Microphones',
}

const micBundleAltsText = {
    _id: '0002',
    class: 'alts',
    title: 'Also Compatible'
}

const IEMBundleRecText = {
    _id: '0003',
    title: 'In-Ear Monitors'
}

const DevicesTable = ({ bundleInfo }) => (
    <div>
        {/* user info table */}
        <MDBTable>
            <MDBTableHead>
                <tr>
                    <th>Name: {bundleInfo.firstName} {bundleInfo.lastName}</th>
                    <th>Organization: {bundleInfo.organization}</th>
                    <th>Email: {bundleInfo.email}</th>
                </tr>
                <tr>
                    <th>Wireless Microphones: {parseInt(bundleInfo.currentMics) + parseInt(bundleInfo.newMics)}</th>
                    <th>In-Ear Monitors: {parseInt(bundleInfo.currentIEMs) + parseInt(bundleInfo.newIEMs)}</th>
                </tr>
            </MDBTableHead>
        </MDBTable>
        {/* devices info table */}
        <MDBTable>
            <MDBTableHead>
                <tr class="bg-dark">
                    <th style={{ color: 'white' }}>Model</th>
                    <th style={{ color: 'white' }}>Quantity</th>
                    <th style={{ color: 'white' }}>Product</th>
                    <th style={{ color: 'white' }}></th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {
                    (bundleInfo.micBundleRecs && bundleInfo.micBundleRecs.length > 0) &&

                    <DeviceTableRow items={[micBundleRecsText]} />
                }

                {
                    (bundleInfo.micBundleRecs && bundleInfo.micBundleRecs.length > 0) &&

                    <DeviceTableRow items={bundleInfo.micBundleRecs} />
                }

                {
                    (bundleInfo.micBundleAlts && bundleInfo.micBundleAlts.length > 0) &&
                    <DeviceTableRow items={[micBundleAltsText]} />
                }
                {
                    (bundleInfo.micBundleAlts && bundleInfo.micBundleAlts.length > 0) &&
                    <DeviceTableRow items={bundleInfo.micBundleAlts} />
                }
                {
                    (bundleInfo.IEMBundleRecs && bundleInfo.IEMBundleRecs.length > 0) &&
                    <DeviceTableRow items={[IEMBundleRecText]} />
                }
                {
                    (bundleInfo.IEMBundleRecs && bundleInfo.IEMBundleRecs.length > 0) &&
                    <DeviceTableRow items={bundleInfo.IEMBundleRecs} />
                }
            </MDBTableBody>
        </MDBTable>
    </div >
);

export default DevicesTable;