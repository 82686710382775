import React, { Fragment } from 'react';

const DeviceTableRow = ({ items }) => {

    const rows = (items && (items.length > 0)) && items.map(item =>
        <tr key={item._id}>
            {
                item.title && <td style={item.class ? { color: 'blue' } :
                    {
                        color: 'orange',
                    }
                }>{item.title ? item.title.replace(/['"]+/g, '') : ''}</td>
            }
            {
                item.extraDesc && <td>{item.extraDesc ? item.extraDesc.replace(/['"]+/g, '') : ''}</td>
            }

            {
                item.modelName && <td style={{
                    width: '18%'
                }}>{item.modelName ? item.modelName.replace(/['"]+/g, '') : ''}</td>
            }

            {
                item.qty && <td style={{
                    width: '14%'
                }}>{item.qty ? item.qty : 0}</td>
            }

            {
                item.name && <td style={{
                    width: '48%'
                }}>{item.name ? item.name.replace(/['"]+/g, '') : ''}</td>
            }

            {
                item.image &&
                <td>
                    <img style={{
                        width: '20%',
                        height: 'auto'
                    }} src={item.image ? decodeURIComponent(item.image.replace(/['"]+/g, '')) : ''} />
                </td>
            }

        </tr >
    )
    return (<Fragment>{rows}</Fragment>)
};

export default DeviceTableRow