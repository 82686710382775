import React from 'react';
import { View } from '@react-pdf/renderer';
import UserInfo from './UserInfo'
import BundleItemsTable from './BundleItemsTable'
import BundleFooter from './BundleFooter'
const Bundle = ({ bundleInfo }) => (
    <View>
        <UserInfo bundleInfo={bundleInfo} />
        <BundleItemsTable bundleInfo={bundleInfo} />
        <BundleFooter />
    </View>
);

export default Bundle;